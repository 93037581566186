<script>
import ApiService from "@services/api.service";

export default {
  name: "NotificationList",
  data() {
    return {
      table: {
        sortBy: "recordDate",
        fields: [
          { key: "recordDate" },
          { key: "title", class: "text-left" },
          { key: "message", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        pageListOptions: [10, 20, 50, 100],
        paging: {
          curPage: 1,
          perPage: 10,
          totalCount: 1
        },
        filter: null
      },
      notification: {
        title: null,
        message: null
      },
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("Notification")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    resendNews(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.get("notification/resend", qs)
        .then(() => {
          this.showToast("success", "Resend Successful");
          this.loadTable();
        })
        .catch(error => {
          this.showToast("error", error);
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    save() {
      this.changeLoader(true);
      let payload = {
        title: this.notification.title,
        message: this.notification.message
      };
      ApiService.post("notification", payload)
        .then(() => {
          this.showToast("success", "Create Successful");
          this.loadTable();
          this.clearForm();
        })
        .catch(error => {
          this.showToast("error", error);
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearForm() {
      this.notification = {
        title: null,
        message: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-form @submit.stop.prevent="save">
        <b-row class="mb-2 mt-2">
          <b-col md="4">
            <label>Title</label>
          </b-col>
          <b-col md="8">
            <b-input v-model="notification.title" class="form-control" />
            <small>Notification title</small>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>Content</label>
          </b-col>
          <b-col md="8">
            <b-textarea v-model="notification.message" rows="5" class="form-control" />
            <small>Notification Content</small>
          </b-col>
        </b-row>
        <b-row class="d-flex ml-auto mt-5">
          <b-col>
            <b-btn block type="submit" variant="success">Save</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col class="text-center">
          <h2>Recent Notification</h2>
        </b-col>
      </b-row>
      <hr />
      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        :sort-desc="true"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
      >
        <template #cell(recordDate)="data">
          {{ data.item.recordDate | dateTime }}
        </template>
        <template #cell(actions)="data">
          <b-btn class="btn btn-sm btn-wide btn-info" @click.stop="resendNews(data.item.code)">Resend</b-btn>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
